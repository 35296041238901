import React from 'react';

import { FaCode, FaGlobe, FaMobile, FaPhone, FaQuestion, FaServer, FaStar, FaUsers } from 'react-icons/fa';

import ServiceItem from '../widgets/service-item';

import './services.scss';

const Services = () => {
  return (
    <section className='container-big content'>
      <div className='section-title'>
        <h2>Leistungen</h2>
      </div>
      <div className='section-desc-wrapper'>
        <div className='section-desc secondary-text'>
          Durch Schnittstellen zu vielen relevanten IT-bezogenen Themen können wir gemeinsam ein klares Gesamtpaket für Ihr Unternehmen
          bauen.
        </div>
      </div>
      <div className='service-items remove-content-padding'>
        <ServiceItem
          icon={<FaGlobe></FaGlobe>}
          title={'Webdesign'}
          content={`Die Website ist die wichtigste digitale Repräsentation eines Unternehmens. Überzeugen Sie potentielle Kunden von sich.`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaMobile></FaMobile>}
          title={'Mobile Development'}
          content={`Sie haben eine tolle App-Idee, deren Umsetzung an fehlender Entwicklerkapazität scheitert? Wir haben Erfahrung mit App-Entwicklung sowie Vertrieb in den Stores.`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaUsers></FaUsers>}
          title={'IT-Beratung'}
          content={`Wir können gemeinsam einen Plan ausarbeiten, mit dem Ihr Unternehmen die moderne IT optimal für sich nutzt.`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaServer></FaServer>}
          title={'IT-Betreuung'}
          content={`Sie suchen einen langfristigen Partner, der Ihre Website oder Ihre IT-Infrastuktur am Laufen hält? Wir arbeiten gern langfristig mit Ihnen zusammen!`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaStar></FaStar>}
          title={'Startup-Team'}
          content={`Sie benötigen ein funktionierendes, eingespieltes Team für die Umsetzung Ihrer Idee? Gemeinsam entwicklen wir mit Ihnen eine Roadmap und setzen die Software um.`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaQuestion></FaQuestion>}
          title={'Was noch?'}
          content={`Haben Sie weitere IT-Projekte, die Sie umsetzen wollen? Kontaktieren Sie uns gern für ein kostenfreies Erstgespäch.`}
        ></ServiceItem>
      </div>
    </section>
  );
};

export default Services;
