import React from 'react';

import './call-to-action-divider.scss';

const CallToActionDivider = () => {
  return (
    <section className='call-to-action-divider bg-gradient'>
      <div className='container'>
        <div className='call-to-action-wrapper'>
          <div className='content'>
            <div className='title'>Wollen wir loslegen?</div>
            <div className='text'>
              Schreiben Sie uns einfach eine kurze Mail mit Ihren Vorstellungen und den Rahmenbedingungen. <br />
              Sie erhalten von uns binnen 24 Stunden eine Antwort.
            </div>
          </div>
          <div className='action'>
            <a className='btn outline white' href='mailto:contact@devtory.io' target="_blank" rel="noopener noreferrer">E-Mail schreiben</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToActionDivider;
