import React from 'react';
import { FaEnvelope, FaFacebook, FaGithub, FaGlobe, FaLinkedin, FaXing } from 'react-icons/fa';
import { Parallax } from 'react-scroll-parallax';

import './team-member.scss';

interface Props {
  imageIsLeft: boolean;
  parallaxSpeed: number;
  name: string;
  title: string;
  image: any;
  imageSquare: any;
  description: string;
  mail?: string;
  website?: string;
  github?: string;
  xing?: string;
  linkedin?: string;
}

const TeamMember = ({
  imageIsLeft,
  parallaxSpeed,
  name,
  title,
  image,
  imageSquare,
  description,
  mail,
  website,
  github,
  xing,
  linkedin,
}: Props) => {
  return (
    <>
      <div className={`team-member-wrapper-small background-shade`}>
        <div className='content'>
          <div className='content-header'>
            <div className='image'>{imageSquare}</div>
            <div className='content-header-title'>
              <div className='name'>{name}</div>
              <div className='title fg-primary'>{title}</div>
            </div>
          </div>
          <div className='desc secondary-text'>{description}</div>
          <div className='icons'>
            <a style={{ display: mail !== undefined ? 'block' : 'none' }} href={`mailto:${mail}`}>
              <FaEnvelope className='icon'></FaEnvelope>
            </a>
            <a style={{ display: website !== undefined ? 'block' : 'none' }} href={website} target={'_blank'}>
              <FaGlobe className='icon'></FaGlobe>
            </a>
            <a style={{ display: github !== undefined ? 'block' : 'none' }} href={github} target={'_blank'}>
              <FaGithub className='icon'></FaGithub>
            </a>
            <a style={{ display: linkedin !== undefined ? 'block' : 'none' }} href={linkedin} target={'_blank'}>
              <FaLinkedin className='icon'></FaLinkedin>
            </a>
            <a style={{ display: xing !== undefined ? 'block' : 'none' }} href={xing} target={'_blank'}>
              <FaXing className='icon'></FaXing>
            </a>
          </div>
        </div>
      </div>
      <div className={`team-member-wrapper ${imageIsLeft ? 'image-left' : 'image-right'}`}>
        <div className='puffer background-shade'></div>
        <Parallax className='parallax-wrapper' speed={parallaxSpeed}>
          <div className='image background-shade'>{image}</div>
        </Parallax>
        <div className='content background-shade'>
          <div className='name'>{name}</div>
          <div className='title fg-primary'>{title}</div>
          <div className='desc secondary-text'>{description}</div>
          <div className='spacer'></div>
          <div className='icons'>
            <div className='spacer-left'></div>
            <a style={{ display: mail !== undefined ? 'block' : 'none' }} href={`mailto:${mail}`}>
              <FaEnvelope className='icon'></FaEnvelope>
            </a>
            <a style={{ display: website !== undefined ? 'block' : 'none' }} href={website} target={'_blank'}>
              <FaGlobe className='icon'></FaGlobe>
            </a>
            <a style={{ display: github !== undefined ? 'block' : 'none' }} href={github} target={'_blank'}>
              <FaGithub className='icon'></FaGithub>
            </a>
            <a style={{ display: linkedin !== undefined ? 'block' : 'none' }} href={linkedin} target={'_blank'}>
              <FaLinkedin className='icon'></FaLinkedin>
            </a>
            <a style={{ display: xing !== undefined ? 'block' : 'none' }} href={xing} target={'_blank'}>
              <FaXing className='icon'></FaXing>
            </a>
            <div className='spacer-right'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamMember;
