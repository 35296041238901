import React from 'react';
import { Parallax } from 'react-scroll-parallax';

import './intro.scss';

export class Intro extends React.Component {
  render() {
    return (
      <section className='intro-section'>
        <Parallax speed={-20}>
          <div className='container content intro-wrapper'>
            <div className='title'>Rent your DEVS</div>
            <div className='slogan secondary-text-2'>
              IT-Team as a Service für Ihr Unternehmen
            </div>
          </div>
        </Parallax>
      </section>
    );
  }
}
