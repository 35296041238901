import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PassionItem from '../widgets/passion-item';

import './passion.scss';

const Passion = () => {
  return (
    <section className='container content'>
      <div className='section-title'>
        <h2>Warum Devtory?</h2>
      </div>
      <div className='section-desc-wrapper'>
        <div className='section-desc secondary-text'>
          
        </div>
      </div>
      <div className='passion-items'>
        <PassionItem
          textLeft={true}
          headline='Einer für alle - alle für einen'
          title='Eingespieltes Team'
          image={<StaticImage alt='team' src={'../../images/passion/team.jpg'} />}
        >
          Als eingespieltes Team, das seit über 5 Jahren in verschiedensten Arten zusammengearbeitet hat und sich auch privat sehr gut versteht, gehen wir jedes Projekt motiviert und optimistisch an!
        </PassionItem>

        <PassionItem
          textLeft={false}
          headline='Auf die Plätze, fertig, los!'
          title='Moderne Zusammenarbeit'
          image={<StaticImage alt='modern' src={'../../images/passion/modern.jpg'} />}
        >
          Von Aufgabenmanagement über Entwicklungsumgebung bis zum Deployment: Mithilfe unserer Erfahrungen schlagen wir gern ein prozess- und softwaregestütztes Vorgehen zur Umsetzung Ihrer Anforderungen vor. Bei Bedarf passen wir uns auch gern Ihrem Vorgehen an.
        </PassionItem>

        <PassionItem
          textLeft={true}
          headline='Über den Tellerrand hinaus'
          title='Wir leben IT-Enabling'
          image={<StaticImage alt='enabling' src={'../../images/passion/enabling.jpg'} />}
        >
          Wir verstehen, dass die IT nicht das Kerngeschäft Ihres Unternehmens ist. Jedoch sind wir davon überzeugt, dass exzellente Software einen maßgeblichen Teil des Erfolges ausmachen kann. 
        </PassionItem>
      </div>
    </section>
  );
};

export default Passion;
