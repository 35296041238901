import React from 'react';

import './passion-item.scss';

interface Props {
  textLeft: boolean;
  headline: string;
  title: string;
  image: any;
  children?: any;
}

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

const PassionItem = ({ textLeft, headline, title, image, children }: Props) => {
  let isMobile = true;
  if (isBrowser) {
    isMobile = window.innerWidth < 699;
  }
  return (
    <div className={`passion-item ${textLeft ? 'text-left' : 'text-right'}`}>
      <div className='text' data-sal='slide-up' data-sal-duration='500' data-sal-easing='ease'>
        <div className='headline fg-primary'>{headline}</div>
        <div className='title'>{title}</div>
        <div className='description secondary-text'>{children}</div>
      </div>
      <div
        className='image'
        data-sal={isMobile ? 'slide-up' : textLeft ? 'slide-left' : 'slide-right'}
        data-sal-duration='500'
        data-sal-delay='150'
        data-sal-easing='ease'
      >
        {image}
      </div>
    </div>
  );
};

export default PassionItem;
