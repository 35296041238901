import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Slider, { Settings } from 'react-slick';

import './skills.scss';

const Skills = () => {
  const settings: Settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '60px',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    variableWidth: true,
    swipeToSlide: false,
  };

  return (
    <section className='skill-section'>
      <Slider className='skills' {...settings}>
        <StaticImage className='skill-image' alt='angular logo' src={'../../images/skills/angular.svg'} />
        <StaticImage className='skill-image' alt='css logo' src={'../../images/skills/css.png'} />
        <StaticImage className='skill-image' alt='flutter logo' src={'../../images/skills/flutter.svg'} />
        <StaticImage className='skill-image' alt='html5 logo' src={'../../images/skills/html-5.png'} />
        <StaticImage className='skill-image' alt='java logo' src={'../../images/skills/java.png'} />
        <StaticImage className='skill-image' alt='javascript logo' src={'../../images/skills/js.png'} />
        <StaticImage className='skill-image' alt='mongodb logo' src={'../../images/skills/mongodb-icon.svg'} />
        <StaticImage className='skill-image large' alt='nodejs logo' src={'../../images/skills/nodejs.svg'} />
        <StaticImage className='skill-image' alt='postgresql logo' src={'../../images/skills/postgresql.svg'} />
        <StaticImage className='skill-image' alt='react logo' src={'../../images/skills/react.svg'} />
        <StaticImage className='skill-image' alt='strapi logo' src={'../../images/skills/strapi.svg'} />
        <StaticImage className='skill-image' alt='typescript logo' src={'../../images/skills/ts.svg'} />
        <StaticImage className='skill-image' alt='vuejs logo' src={'../../images/skills/vue.svg'} />
      </Slider>
    </section>
  );
};

export default Skills;
