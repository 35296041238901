import React from 'react';
import './service-item.scss';

interface Props {
  icon: any;
  title: string;
  content: any;
}

const ServiceItem = ({ icon, title, content }: Props) => {
  return (
    <div className='service-item' data-sal='zoom-in' data-sal-duration='500' data-sal-easing='ease'>
      <div className='image bg-primary'>{icon}</div>

      <div className='title'>{title}</div>

      <div className='content secondary-text'>{content}</div>
    </div>
  );
};

export default ServiceItem;
