import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import TeamMember from '../widgets/team-member';

import './team.scss';

const Team = () => {
  return (
    <section className='container content'>
      <div className='section-title'>
        <h2>Das Team</h2>
      </div>
      <div className='section-desc-wrapper'>
        <div className='section-desc secondary-text'>
          Ein seit über fünf Jahren eingespieltes Entwicklerteam, das sich jeder Herausforderung annimmt. Das ist Ihr neues IT-Department:
        </div>
      </div>
      <div className='team-members'>
        <TeamMember
          imageIsLeft={true}
          parallaxSpeed={10}
          name='Martin Weber'
          title='M.Sc. Angewandte Informatik'
          image={<StaticImage alt='martin weber' src={'../../images/team/martin.jpg'} />}
          imageSquare={<StaticImage alt='martin weber' src={'../../images/team/martin_square.jpg'} />}
          description='Ich brenne für die Entwicklung eines nachhaltigen und skalierbaren Softwaregerüstes! Sei es bei der Implementierung neuer Funktionen oder der Aufbau ganz neuer Apps mit modernen Technologien.'
          mail='martin@devtory.io'
          website='https://martinweber93.github.io/'
          xing='https://www.xing.com/profile/Martin_Weber300/'
          linkedin='https://www.linkedin.com/in/martin-weber93'
          github='https://github.com/ThunderAnimal'
        ></TeamMember>

        <TeamMember
          imageIsLeft={false}
          parallaxSpeed={-7}
          name='Julian Naumann'
          title='B.Sc. Wirtschaftsinformatik'
          image={<StaticImage alt='julian naumann' src={'../../images/team/julian.jpg'} />}
          imageSquare={<StaticImage alt='julian naumann' src={'../../images/team/julian_square.jpg'} />}
          description='Als Entwickler lernte ich, dass IT in Unternehmen noch viel mehr ist als nur Software und Server. Da mich das weite Spektrum interessiert bin ich in die Beratung gewechselt und studiere berufsbegleitend IT-Management.'
          mail='julian@devtory.io'
          website='https://www.nauweb.de/'
          xing='https://www.xing.com/profile/Julian_Naumann4/cv'
          linkedin='https://www.linkedin.com/in/julian-naumann-60b8a9174/'
        ></TeamMember>

        <TeamMember
          imageIsLeft={true}
          parallaxSpeed={-9}
          name='Alexander Kirsch'
          title='B.Sc. Wirtschaftsinformatik'
          image={<StaticImage alt='alexander kirsch' src={'../../images/team/alex.jpg'} />}
          imageSquare={<StaticImage alt='alexander kirsch' src={'../../images/team/alex_square.jpg'} />}
          description='Es ist beeindruckend, was für umfangreiche und schöne Anwendungen auf Smartphones und Webseiten laufen können. Deshalb habe ich mich auf UX-Design und Frontend-Entwicklung spezialisiert.'
          mail='alexander@devtory.io'
          xing='https://www.xing.com/profile/Alexander_Kirsch55/cv'
        ></TeamMember>

        <TeamMember
          imageIsLeft={false}
          parallaxSpeed={9}
          name='Gamzat Mukailov'
          title='B.Sc. Wirtschaftsinformatik'
          image={<StaticImage alt='gamzat mukailov' src={'../../images/team/gamzat.jpg'} />}
          imageSquare={<StaticImage alt='gamzat mukailov' src={'../../images/team/gamzat_square.jpg'} />}
          description='Es begeistert mich, die individuellen Anforderungen gemeinsam mit Ihnen auszugestalten und daraus eine nutzerfreundliche, stabile Anwendung zu entwickeln.'
          mail='gamzat@devtory.io'
          xing='https://www.xing.com/profile/Gamzat_Mukailov/cv'
          linkedin='https://www.linkedin.com/in/gamzat-mukailov-006a67104/'
        ></TeamMember>
      </div>
    </section>
  );
};

export default Team;
