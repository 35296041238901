import * as React from 'react';
import Seo from '../components/utils/seo';
import { Layout } from '../components/layout';
import { Intro } from '../components/sections/intro';
import Services from '../components/sections/services';
import Skills from '../components/sections/skills';
import Passion from '../components/sections/passion';
import CallToActionDivider from '../components/sections/call-to-action-divider';
import Team from '../components/sections/team';

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo></Seo>
      <Intro></Intro>
      <Services></Services>
      <Skills></Skills>
      <Passion></Passion>
      <CallToActionDivider></CallToActionDivider>
      <Team></Team>
    </Layout>
  );
};

export default IndexPage;
